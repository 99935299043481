import React, { useState } from 'react';
import axios from 'axios';


function Contact(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [msg, setMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const apiUrl = process.env.REACT_APP_CONTACT_URL;

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangeSubject = (e) => {
    const subject = e.target.value;
    setSubject(subject);
  };

  const onChangeMsg = (e) => {
    const msg = e.target.value;
    setMsg(msg);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSuccess(true);
    alert("Your message has been sent successfully !!");
    axios
      .post(`${apiUrl}send-mail`, { subject: subject, subject, message: msg, name: name, email: email }, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        // Handle the response data here
        console.log("success");
      })
      .catch((error) => {
        // Handle any errors here
        console.error(error);
      });
  };
  return (
  

      <section id="contact" className="container-fluid bg-light overflow-hidden px-lg-0" style={{ margin: '6rem 0' }}>
        <div className="container contact px-lg-0">
          <div className="row g-0 mx-lg-0">
            <div className="col-lg-6 contact-text py-5 wow fadeIn" data-wow-delay="0.5s">
              <div className="p-lg-5 ps-lg-0">
                <h6 className="text-primary">Contact Us</h6>
                <h1 className="mb-4">Feel Free To Contact Us</h1>
                <p className="mb-4">
                  We’d love to hear from you! Please take a moment to fill out the form below with your details and message.
                  Thank you for reaching out !

                </p>
                <p className="mb-2"><i className="fa fa-map-marker me-3" /><span style={{marginLeft : '5px'}}>Office 202-201-247, Insurance Building, Al Rega</span></p>
                <p className="mb-2"><i className="fa fa-mobile-alt me-3" /><span style={{marginLeft : '5px'}}>+971-54-4227612</span></p>
                <p className="mb-2"><i className="fa fa-phone-alt me-3" />+971-4-2832717</p>
                <p className="mb-2"><i className="fa fa-envelope me-3"></i>
                  <a href="mailto:contact@tlfsolarenergysystemsrental.com" style={{ color: 'inherit', textDecoration: 'none' }}
                  >
                     contact@tlfsolarenergysystemsrental.com
                  </a>
                </p>
                <div className="d-flex pt-2">
                  <a className="btn rounded-circle btn-outline-dark btn-social me-2" href="https://www.instagram.com/tlf_solar_energy/profilecard/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" /></a>
                  <a className="btn rounded-circle btn-outline-dark btn-social me-2" href="https://www.linkedin.com/company/tlf-solar-energy-systems-rental" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in" /></a>
                  <a className="btn rounded-circle btn-outline-dark btn-social me-2" ><i className="fab fa-youtube"></i></a>
                  <a className="btn rounded-circle btn-outline-dark btn-social me-2" ><i className="fab fa-facebook-f"></i></a>
                </div>
                <br />

                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input type="text" onChange={onChangeName} className="form-control" id="name" name="name" placeholder="Your Name" />
                        <label htmlFor="name">Your Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input type="email" className="form-control" onChange={onChangeEmail} id="email" placeholder="Your Email" name="email" />
                        <label htmlFor="email">Your Email</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input type="text" className="form-control" id="subject" onChange={onChangeSubject} placeholder="Subject" name="subject" />
                        <label htmlFor="subject">Subject</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea onChange={onChangeMsg} className="form-control" placeholder="Leave a message here" id="message" style={{ height: 100 }} defaultValue={""} name="message" />
                        <label htmlFor="message">Message</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-primary rounded-pill py-3 px-5" type="submit">Send Message</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 pe-lg-0" style={{ minHeight: 400 }}>
              <div className="position-relative h-100">
                <iframe className="position-absolute w-100 h-100" style={{ objectFit: 'cover' }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.258622613551!2d55.327320900000004!3d25.261884100000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5cdace5443e1%3A0xbb18f8224a95a137!2sDubai%20Insurance%20Building%20-%20Al%20Rigga%20Rd%20-%20Deira%20-%20Dubai%20-%20%C3%89mirats%20arabes%20unis!5e0!3m2!1sfr!2stn!4v1725617705728!5m2!1sfr!2stn" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} />
             </div>
            </div>
          </div>
        </div>
    </section>
  );
}

export default Contact;