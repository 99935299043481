import React, { useEffect, useState, useRef } from "react";
import CountUp from "react-countup";
function Counter(props) {
 
    return (
      <div className="container-xxl py-5" >
        <div className="container">
          <div className="row g-5">
            {/* Happy Customers */}
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
              <div className="d-flex align-items-center mb-4">
                <div className="btn-lg-square bg-primary rounded-circle me-3">
                  <i className="fa fa-users text-white" />
                </div>
                <h1 className="mb-0">
                <CountUp start={0} end={356} delay={0.5} duration={2} />
                </h1>
              </div>
              <h5 className="mb-3">Happy Customers</h5>
              <span>Our clients' satisfaction is our top priority, ensuring quality and reliability in every project</span>
            </div>
  
            {/* Projects Done */}
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
              <div className="d-flex align-items-center mb-4">
                <div className="btn-lg-square bg-primary rounded-circle me-3">
                  <i className="fa fa-check text-white" />
                </div>
                <h1 className="mb-0">
               <CountUp start={0} end={421} delay={0.5} duration={2} />
                </h1>
              </div>
              <h5 className="mb-3">Projects Done</h5>
              <span>Successfully executed numerous solar projects with exceptional results and client satisfaction</span>
            </div>
  
            {/* Innovative Solutions */}
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
              <div className="d-flex align-items-center mb-4">
                <div className="btn-lg-square bg-primary rounded-circle me-3">
                  <i className="fa fa-award text-white" />
                </div>
                <h1 className="mb-0">
           <CountUp start={0} end={150} delay={0.5} duration={2} />
                </h1>
              </div>
              <h5 className="mb-3">Innovative Solutions</h5>
              <span>Delivering cutting-edge solar technology to meet energy needs efficiently</span>
            </div>
  
            {/* Expert Workers */}
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
              <div className="d-flex align-items-center mb-4">
                <div className="btn-lg-square bg-primary rounded-circle me-3">
                  <i className="fa fa-users-cog text-white" />
                </div>
                <h1 className="mb-0">
                    <CountUp start={0} end={37} delay={0.5} duration={2} /> 
                </h1>
              </div>
              <h5 className="mb-3">Expert Workers</h5>
              <span>Skilled technicians ensuring top-notch installations and maintenance for all our solar solutions</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

export default Counter;