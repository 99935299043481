import React from 'react';
import Topbar from './topbar';
import Nabvar from './nabvar';

function Home(props) {
    return (

        <section id="home" className="container-fluid p-0 pb-5 wow fadeIn" data-wow-delay="0.1s">
          <div className="owl-carousel header-carousel position-relative">
            <div className="owl-carousel-item position-relative" data-dot="<img src='img/carousel-1.jpg'>">
              <img className="img-fluid" src="img/carousel-1.jpg" alt="cover-img-1" />
              <div className="owl-carousel-inner">
                <div className="container">
                  <div className="row justify-content-start">
                    <div className="col-10 col-lg-8">
                      <h1 className="display-2 text-white animated slideInDown">Pioneers Of Solar And Renewable Energy</h1>
                      <p className="fs-5 fw-medium text-white mb-4 pb-3">Harnessing the power of the sun to create a sustainable tomorrow. Our cutting-edge solar solutions provide clean, efficient, and reliable energy for a brighter future.</p>
                      <a href="#quote" className="btn btn-primary rounded-pill py-3 px-5 animated slideInLeft">Get a Free Quotation</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="owl-carousel-item position-relative" data-dot="<img src='img/carousel-2.jpg'>">
              <img className="img-fluid" src="img/carousel-2.jpg" alt="cover-img-2" />
              <div className="owl-carousel-inner">
                <div className="container">
                  <div className="row justify-content-start">
                    <div className="col-10 col-lg-8">
                      <h1 className="display-2 text-white animated slideInDown">Power Your Future with Solar</h1>
                      <p className="fs-5 fw-medium text-white mb-4 pb-3">Affordable, sustainable energy solutions for everyone.</p>
                      <a href="#quote" className="btn btn-primary rounded-pill py-3 px-5 animated slideInLeft">Get a Free Quotation</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="owl-carousel-item position-relative" data-dot="<img src='img/carousel-3.jpg'>">
              <img className="img-fluid" src="img/carousel-3.jpg" alt="cover-img-3" />
              <div className="owl-carousel-inner">
                <div className="container">
                  <div className="row justify-content-start">
                    <div className="col-10 col-lg-8">
                      <h1 className="display-2 text-white animated slideInDown">Go Green, Save More</h1>
                      <p className="fs-5 fw-medium text-white mb-4 pb-3">Switch to solar and reduce your energy costs with ease. Our rental systems offer eco-friendly power at a fraction of the cost, without the need for upfront investment.</p>
                      <a href="#quote" className="btn btn-primary rounded-pill py-3 px-5 animated slideInLeft">Get a Free Quotation</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    );
}

export default Home;