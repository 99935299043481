import React from 'react';
import Nabvar from './nabvar';
import Footer from './footer';
import Topbar from './topbar';
import RequestQuote from './request-quote';

function Project(props) {
    return (
        <section id="project" className="container-xxl py-5">
        <div className="container">
          <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 600}}>
            <h6 className="text-primary">Our Projects</h6>
            <h1 className="mb-4">Visit Our Latest Solar And Renewable Energy Projects</h1>
          </div>
          <div className="row mt-n2 wow fadeInUp" data-wow-delay="0.3s">
            <div className="col-12 text-center">
              <ul className="list-inline mb-5" id="portfolio-flters">
                <li className="mx-2 active" data-filter="*">All</li>
                <li className="mx-2" data-filter=".first">Solar Panels</li>
              </ul>
            </div>
          </div>
          <div className="row g-4 portfolio-container wow fadeInUp" data-wow-delay="0.5s">
            <div className="col-lg-4 col-md-6 portfolio-item first">
              <div className="portfolio-img rounded overflow-hidden">
                <img className="img-fluid" src="img/img-600x400-6.jpg" alt="project-1" />
                <div className="portfolio-btn">
                  <a className="btn btn-lg-square btn-outline-light rounded-circle mx-1" ><i className="fa fa-link" /></a>
                </div>
              </div>
              <div className="pt-3">
                <p className="text-primary mb-0">Solar Panels</p>
                <hr className="text-primary w-25 my-2" />
                <h5 className="lh-base">We Are pioneers of solar &amp; renewable energy industry</h5>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item first">
              <div className="portfolio-img rounded overflow-hidden">
                <img className="img-fluid" src="img/img-600x400-5.jpg" alt="project-2" />
                <div className="portfolio-btn">
                  <a className="btn btn-lg-square btn-outline-light rounded-circle mx-1" ><i className="fa fa-link" /></a>
                </div>
              </div>
              <div className="pt-3">
                <p className="text-primary mb-0">Solar Panels</p>
                <hr className="text-primary w-25 my-2" />
                <h5 className="lh-base">We Are pioneers of solar &amp; renewable energy industry</h5>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item first">
              <div className="portfolio-img rounded overflow-hidden">
                <img className="img-fluid" src="img/img-600x400-4.jpg" alt="project-3" />
                <div className="portfolio-btn">
                  <a className="btn btn-lg-square btn-outline-light rounded-circle mx-1" ><i className="fa fa-link" /></a>
                </div>
              </div>
              <div className="pt-3">
                <p className="text-primary mb-0">Solar Panels</p>
                <hr className="text-primary w-25 my-2" />
                <h5 className="lh-base">We Are pioneers of solar &amp; renewable energy industry</h5>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item first">
              <div className="portfolio-img rounded overflow-hidden">
                <img className="img-fluid" src="img/img-600x400-3.jpg" alt="project-4" />
                <div className="portfolio-btn">
                  <a className="btn btn-lg-square btn-outline-light rounded-circle mx-1" ><i className="fa fa-link" /></a>
                </div>
              </div>
              <div className="pt-3">
                <p className="text-primary mb-0">Solar Panels</p>
                <hr className="text-primary w-25 my-2" />
                <h5 className="lh-base">We Are pioneers of solar &amp; renewable energy industry</h5>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item first">
              <div className="portfolio-img rounded overflow-hidden">
                <img className="img-fluid" src="img/img-600x400-2.jpg" alt="project-5" />
                <div className="portfolio-btn">
                  <a className="btn btn-lg-square btn-outline-light rounded-circle mx-1" ><i className="fa fa-link" /></a>
                </div>
              </div>
              <div className="pt-3">
                <p className="text-primary mb-0">Solar Panels</p>
                <hr className="text-primary w-25 my-2" />
                <h5 className="lh-base">We Are pioneers of solar &amp; renewable energy industry</h5>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item first">
              <div className="portfolio-img rounded overflow-hidden">
                <img className="img-fluid" src="img/img-600x400-1.jpg" alt="project-6" />
                <div className="portfolio-btn">
                  <a className="btn btn-lg-square btn-outline-light rounded-circle mx-1" ><i className="fa fa-link" /></a>
                </div>
              </div>
              <div className="pt-3">
                <p className="text-primary mb-0">Solar Panels</p>
                <hr className="text-primary w-25 my-2" />
                <h5 className="lh-base">We Are pioneers of solar &amp; renewable energy industry</h5>
              </div>
            </div>
          </div>
        </div>
      </section>

    );
}

export default Project;