import React from 'react';

function Topbar(props) {
    return (
        <div className="container-fluid bg-dark p-0">
        <div className="row gx-0 d-none d-lg-flex">
          <div className="col-lg-7 px-5 text-start">
                <div className="h-100 d-inline-flex align-items-center me-4">
                    <small className="fa fa-map-marker-alt text-primary me-2"></small>
                    <small>Office 202-201-247, Insurance Building, Al Rega</small>
                </div>
                <div className="h-100 d-inline-flex align-items-center">
                    <small className="far fa-clock text-primary me-2"></small>
                    <small>Mon - Fri : 09.00 AM - 09.00 PM</small>
                </div>
            </div>
          <div className="col-lg-5 px-5 text-end">
          <div className="h-100 d-inline-flex align-items-center me-4">
              <small className="fa fa-envelope text-primary me-2" />
              <small> contact@tlfsolarenergysystemsrental.com</small>
            </div>
            <div className="h-100 d-inline-flex align-items-center me-4">
              <small className="fa fa-mobile text-primary me-2" />
              <small>+971-54-4227612</small>
            </div>
            <div className="h-100 d-inline-flex align-items-center me-4">
              <small className="fa fa-phone-alt text-primary me-2" />
              <small>+971-4-2832717</small>
            </div>  
            <div className="h-100 d-inline-flex align-items-center mx-n2">
              <a className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary" href="https://www.linkedin.com/company/tlf-solar-energy-systems-rental" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in" /></a>
              <a className="btn btn-square btn-link rounded-0 border-end" href="https://www.instagram.com/tlf_solar_energy/profilecard/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" /></a>
              <a className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary" ><i className="fab fa-facebook-f"></i></a>
              <a className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary" ><i className="fab fa-youtube"></i></a>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Topbar;