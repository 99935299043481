import React from 'react';

function Nabvar(props) {
  return (
<nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0">
  <div className="navbar-brand d-flex align-items-center border-end px-4 px-lg-5">
    <img src="img/logo.png" alt="Solartec Logo" style={{ height: '160px' }} />
  </div>
  <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
    <span className="navbar-toggler-icon" />
  </button>
  <div className="collapse navbar-collapse" id="navbarCollapse">
    <div className="navbar-nav ms-auto p-4 p-lg-0">
      <a href="#home" className="nav-item nav-link active">
        Home
      </a>
      <a href="#about" className="nav-item nav-link">
        About
      </a>
      <a href="#service" className="nav-item nav-link">
        Service
      </a>
      <a href="#project" className="nav-item nav-link">
        Project
      </a>
      <a href="#contact" className="nav-item nav-link">
        Contact
      </a>
    </div>
    <a href="#quote" className="btn btn-primary rounded-0 py-4 px-lg-5 d-none d-lg-block">
      Get A Quote<i className="fa fa-arrow-right ms-3" />
    </a>
  </div>
</nav>

  );
}

export default Nabvar;