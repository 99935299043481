import { useRoutes, Navigate } from 'react-router-dom';

import NotFound from './not-found';
import Main from './main';




export default function Router() {
  
    return useRoutes([
        {
            path: '/',
            children: [
                { path: '/', element: < Main /> },
                { path: '*', element: <Navigate to="/404" /> }, 
                { path: '404', element: <NotFound /> } 
            ]
        },
        {
       
      },
      {
     
    }
  
    ]);
  
    
  }