import React from 'react';
import Footer from './footer';
import RequestQuote from './request-quote';
import Testimonial from './testimonial';
import Home from './home';
import Topbar from './topbar';
import Nabvar from './nabvar';
import Service from './service';
import Project from './project';
import Contact from './contact';
import About from './about';
import CountUp from "react-countup";
import Counter from './counter';

function Main(props) {
    return (
        <div>
        <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
          <div className="spinner-border text-primary" style={{width: '3rem', height: '3rem'}} role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
        <Topbar />
        <Nabvar />
         <Home />
        {/* Carousel End */}
        {/* Feature Start */}
        <Counter />

        {/* Feature Start */}

        {/* About Start */}
         <About />
        {/* About End */}
        {/* Service Start */}

       <Service />
        {/* Service End */}
        {/* Feature Start */}
        <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
          <div className="container feature px-lg-0">
            <div className="row g-0 mx-lg-0">
              <div className="col-lg-6 feature-text py-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="p-lg-5 ps-lg-0">
                  <h6 className="text-primary">Why Choose Us!</h6>
                  <h1 className="mb-4">Complete Commercial &amp; Residential Solar Systems</h1>
                  <p className="mb-4 pb-2">At TLF Solar, we offer comprehensive solar solutions designed to meet both commercial and residential needs. Our services include top-quality solar panel rentals, expert installation, and ongoing support to ensure you get the most out of your solar energy system. Choose us for a seamless transition to renewable energy with unparalleled service and support.</p>
                  <div className="row g-4">
                    <div className="col-6">
                      <div className="d-flex align-items-center">
                        <div className="btn-lg-square bg-primary rounded-circle">
                          <i className="fa fa-check text-white" />
                        </div>
                        <div className="ms-4">
                          <p className="mb-0">Quality</p>
                          <h5 className="mb-0">Services</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="d-flex align-items-center">
                        <div className="btn-lg-square bg-primary rounded-circle">
                          <i className="fa fa-user-check text-white" />
                        </div>
                        <div className="ms-4">
                          <p className="mb-0">Expert</p>
                          <h5 className="mb-0">Workers</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="d-flex align-items-center">
                        <div className="btn-lg-square bg-primary rounded-circle">
                          <i className="fa fa-drafting-compass text-white" />
                        </div>
                        <div className="ms-4">
                          <p className="mb-0">Free</p>
                          <h5 className="mb-0">Consultation</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="d-flex align-items-center">
                        <div className="btn-lg-square bg-primary rounded-circle">
                          <i className="fa fa-headphones text-white" />
                        </div>
                        <div className="ms-4">
                          <p className="mb-0">Customer</p>
                          <h5 className="mb-0">Support</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 pe-lg-0 wow fadeIn" data-wow-delay="0.5s" style={{minHeight: 400}}>
                <div className="position-relative h-100">
                  <img className="position-absolute img-fluid w-100 h-100" src="img/feature.jpg" style={{objectFit: 'cover'}} alt="feature" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Feature End */}
        {/* Projects Start */}
      <Project />
      <RequestQuote />

        {/* Projects End */}
        {/* Quote Start */}
        {/* Quote End */}
        {/* Team Start */}
        <div className="container-xxl py-5">
          <div className="container">
            <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 600}}>
              <h6 className="text-primary">Our Team</h6>
              <h1 className="mb-4">Meet Our Talented Team</h1>
            </div>
            <div className="row g-4 justify-content-center">
              <div className="col-lg-8 col-md-12 text-center wow fadeInUp" data-wow-delay="0.3s">
                <div className="team-item rounded overflow-hidden">
                  <div className="d-flex justify-content-center">
                    <img className="img-fluid mb-4" src="img/team-1.png" width={600} height={400} alt="Our Team" />
                  </div>
                  <div className="p-4">
                    <h5 className="mb-1">Our Dedicated Team</h5>
                    <p className="text-muted">Comprising talented professionals with extensive experience in various fields, our team is dedicated to delivering excellence and driving success. Each member brings unique skills and a collaborative spirit to our projects.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Team End */}
        {/* Testimonial Start */}
         <Testimonial />
        {/* Testimonial End */}
      



       <Contact />
        {/* Footer Start */}
        <Footer />
        {/* Footer End */}
        {/* Back to Top */}
        <a href="#" className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"><i className="bi bi-arrow-up" /></a>
      </div>
    );
}

export default Main;