import React, { useState, useRef } from 'react';
import axios from 'axios';

function RequestQuote(props) {
    const selectRef = useRef(null);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [msg, setMsg] = useState("");
    const [selectedService, setSelectedService] = useState('');

    const [success, setSuccess] = useState(false);
    const apiUrl = process.env.REACT_APP_CONTACT_URL;
  
    const onChangeName = (e) => {
      const name = e.target.value;
      setName(name);
    };
  
    const onChangeEmail = (e) => {
      const email = e.target.value;
      setEmail(email);
    };
  
    const onChangeMobile = (e) => {
      const mobile = e.target.value;
      setMobile(mobile);
    };
  

    const handleSelectChange = () => {
        const selectedOption = selectRef.current.options[selectRef.current.selectedIndex];
        setSelectedService(selectedOption.text);
      };
  
    const onChangeMsg = (e) => {
      const msg = e.target.value;
      setMsg(msg);
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      setSuccess(true);
      alert("Your message has been sent successfully !!");
   
      axios
        .post(`${apiUrl}get-quote`, { mobile: mobile, service:selectedService , message: msg, name: name, email: email }, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          // Handle the response data here
          console.log("success");
        })
        .catch((error) => {
          // Handle any errors here
          console.error(error);
        });
    };
    return (
        <div id="quote" className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div className="container quote px-lg-0">
          <div className="row g-0 mx-lg-0">
            <div className="col-lg-6 ps-lg-0 wow fadeIn" data-wow-delay="0.1s" style={{minHeight: 400}}>
              <div className="position-relative h-100">
                <img className="position-absolute img-fluid w-100 h-100" src="img/quote.jpg" style={{objectFit: 'cover'}} alt="quote-im" />
              </div>
            </div>
            <div className="col-lg-6 quote-text py-5 wow fadeIn" data-wow-delay="0.5s">
              <div className="p-lg-5 pe-lg-0">
                <h6 className="text-primary">Free Quote</h6>
                <h1 className="mb-4">Get A Free Quote</h1>
                <p className="mb-4 pb-2">Choose the service you need from the options below and fill out the form to receive your free quote. We're here to help you with the best solution for your needs.</p>
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-12 col-sm-6">
                      <input type="text" name="name" id="name" onChange={onChangeName} className="form-control border-0" placeholder="Your Name" style={{height: 55}} />
                    </div>
                    <div className="col-12 col-sm-6">
                      <input type="email" onChange={onChangeEmail} id="email" className="form-control border-0" placeholder="Your Email" style={{height: 55}} />
                    </div>
                    <div className="col-12 col-sm-6">
                      <input type="text" className="form-control border-0" onChange={onChangeMobile} placeholder="Your Mobile" style={{height: 55}} />
                    </div>
                    <div className="col-12 col-sm-6" value={selectedService} onChange={handleSelectChange}>
                    <select ref={selectRef} className="form-select border-0" style={{height: 55}} defaultValue={'DEFAULT'}>
                    <option value="DEFAULT" disabled>Select A Service</option>
                    <option value="1">Solar Panel Installation</option>
                    <option value="2">Solar Panel Maintenance</option>
                    <option value="3">Solar Energy Consulting</option>
                    <option value="4">Residential Solar Solutions</option>
                    <option value="5">Commercial Solar Solutions</option>
                    <option value="6">Energy Monitoring</option>
                    </select>
                    </div>
                    <div className="col-12">
                      <textarea  onChange={onChangeMsg} id="message" name="message"  className="form-control border-0" placeholder="Special Note" defaultValue={""} />
                    </div>
                    <div className="col-12">
                      <button className="btn btn-primary rounded-pill py-3 px-5" type="submit">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default RequestQuote;