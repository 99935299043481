import React from 'react';


function Service(props) {
    return (
        <section id="service" className="container-xxl py-5">
        <div className="container">
          <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 600}}>
            <h6 className="text-primary">Our Services</h6>
            <h1 className="mb-4">We Are Pioneers In The World Of Renewable Energy</h1>
          </div>
          <div className="row g-4">
            <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
              <div className="service-item rounded overflow-hidden">
                <img className="img-fluid" src="img/img-600x400-4.jpg" alt="Solar Panel Installation" />
                <div className="position-relative p-4 pt-0">
                  <div className="service-icon">
                    <i className="fa fa-solar-panel fa-3x" />
                  </div>
                  <h4 className="mb-3">Solar Panel Installation</h4>
                  <p>Professional installation of solar panels to harness renewable energy efficiently. Our experienced team ensures a seamless and reliable setup for both residential and commercial properties.</p>
                  <a className="small fw-medium" >Read More<i className="fa fa-arrow-right ms-2" /></a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
              <div className="service-item rounded overflow-hidden">
                <img className="img-fluid" src="img/img-600x400-3.jpg" alt="Solar Panel Maintenance" />
                <div className="position-relative p-4 pt-0">
                  <div className="service-icon">
                    <i className="fa fa-tools fa-3x" />
                  </div>
                  <h4 className="mb-3">Solar Panel Maintenance</h4>
                  <p>Comprehensive maintenance services to ensure your solar panels operate at peak efficiency. Our team provides regular check-ups and prompt repairs to keep your system running smoothly.</p>
                  <a className="small fw-medium" >Read More<i className="fa fa-arrow-right ms-2" /></a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
              <div className="service-item rounded overflow-hidden">
                <img className="img-fluid" src="img/img-600x400-2.jpg" alt="Solar Energy Consulting" />
                <div className="position-relative p-4 pt-0">
                  <div className="service-icon">
                    <i className="fa fa-calendar fa-3x" />
                  </div>
                  <h4 className="mb-3">Solar Energy Consulting</h4>
                  <p>Expert consulting to help you make informed decisions about solar energy. We assess your needs, provide recommendations, and guide you through the process of adopting solar technology.</p>
                  <a className="small fw-medium" >Read More<i className="fa fa-arrow-right ms-2" /></a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
              <div className="service-item rounded overflow-hidden">
                <img className="img-fluid" src="img/img-600x400-1.jpg" alt="Residential Solar Solutions" />
                <div className="position-relative p-4 pt-0">
                  <div className="service-icon">
                    <i className="fa fa-home fa-3x" />
                  </div>
                  <h4 className="mb-3">Residential Solar Solutions</h4>
                  <p>Tailored solar solutions for residential properties, designed to meet your home's energy needs and help you save on electricity bills. We offer installation, maintenance, and support for your home solar system.</p>
                  <a className="small fw-medium" >Read More<i className="fa fa-arrow-right ms-2" /></a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
              <div className="service-item rounded overflow-hidden">
                <img className="img-fluid" src="img/img-600x400-6.jpg" alt="Commercial Solar Solutions" />
                <div className="position-relative p-4 pt-0">
                  <div className="service-icon">
                    <i className="fa fa-building fa-3x" />
                  </div>
                  <h4 className="mb-3">Commercial Solar Solutions</h4>
                  <p>Customized solar solutions for commercial properties to enhance energy efficiency and reduce operational costs. We handle everything from design to installation and maintenance.</p>
                  <a className="small fw-medium" >Read More<i className="fa fa-arrow-right ms-2" /></a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
              <div className="service-item rounded overflow-hidden">
                <img className="img-fluid" src="img/img-600x400-5.jpg" alt="Energy Monitoring" />
                <div className="position-relative p-4 pt-0">
                  <div className="service-icon">
                    <i className="fa fa-chart-line fa-3x" />
                  </div>
                  <h4 className="mb-3">Energy Monitoring</h4>
                  <p>Advanced energy monitoring tools to track and optimize your solar system's performance. Get real-time insights into energy production and consumption to maximize efficiency.</p>
                  <a className="small fw-medium" >Read More<i className="fa fa-arrow-right ms-2" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    );
}

export default Service;