import React from 'react';

function About(props) {
    return (
      <section id="about" className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
      <div className="container about px-lg-0">
        <div className="row g-0 mx-lg-0">
          <div className="col-lg-6 ps-lg-0 wow fadeIn" data-wow-delay="0.1s" style={{minHeight: 400}}>
            <div className="position-relative h-100">
              <img className="position-absolute img-fluid w-100 h-100" src="img/about.jpg" style={{objectFit: 'cover'}} alt="cover" />
            </div>
          </div>
          <div className="col-lg-6 about-text py-5 wow fadeIn" data-wow-delay="0.5s">
            <div className="p-lg-5 pe-lg-0">
              <h6 className="text-primary">About Us</h6>
              <h1 className="mb-4">15+ Years Experience In Solar &amp; Renewable Energy Industry</h1>
              <p>We excel in delivering top-notch, sustainable energy solutions. Our expertise ensures reliable, high-quality service suited to your needs.</p>
              <p><i className="fa fa-check-circle text-primary me-3" />Proven track record of successful solar projects</p>
              <p><i className="fa fa-check-circle text-primary me-3" />Commitment to cutting-edge technology and sustainability</p>
              <p><i className="fa fa-check-circle text-primary me-3" />Dedicated to exceptional customer service and support</p>
              <a href="#service" className="btn btn-primary rounded-pill py-3 px-5 mt-3">Explore More</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    );
}

export default About;