import React, { useEffect } from "react";
import { appendScript } from "./append-script";
import Routes from "./routes";

function App() {
  useEffect(() => {
    const loadScripts = async () => {
      await new Promise((resolve) => appendScript('https://code.jquery.com/jquery-3.4.1.min.js', resolve));
      await new Promise((resolve) => appendScript('https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/js/bootstrap.bundle.min.js', resolve));
      await new Promise((resolve) => appendScript('lib/wow/wow.min.js', resolve));
      await new Promise((resolve) => appendScript('lib/easing/easing.min.js', resolve));
      await new Promise((resolve) => appendScript('lib/waypoints/waypoints.min.js', resolve));
      await new Promise((resolve) => appendScript('lib/owlcarousel/owl.carousel.min.js', resolve));
      await new Promise((resolve) => appendScript('lib/isotope/isotope.pkgd.min.js', resolve));
      await new Promise((resolve) => appendScript('lib/lightbox/js/lightbox.min.js', resolve));
      await new Promise((resolve) => appendScript('js/main.js', resolve));
 
    };
    loadScripts();


    
  }, []);

  return <Routes />;
}

export default App;
