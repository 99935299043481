import React from 'react';
import { Link } from 'react-router-dom';

function Footer(props) {
    return (
        <div className="container-fluid bg-dark text-body footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-3 col-md-6">
                <h5 className="text-white mb-4">Contact</h5>
                <p className="mb-2"><i className="fa fa-mobile-alt me-3" />+971-54-4227612</p>
                <p className="mb-2"><i className="fa fa-phone-alt me-3"  /><span style={{marginLeft : '-5px'}}>+971-4-2832717</span></p>
                <p className="mb-2"><i className="fa fa-map-marker me-3" />Office 202-201-247, Insurance Building, Al Rega</p>
                <div className="d-flex pt-2">
                  <a className="btn btn-square btn-outline-light btn-social" href="https://www.instagram.com/tlf_solar_energy/profilecard/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" /></a>
                  <a className="btn btn-square btn-outline-light btn-social" href="https://www.linkedin.com/company/tlf-solar-energy-systems-rental" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in" /></a>
                  <a className="btn btn-square btn-outline-light btn-social" ><i className="fab fa-youtube"></i></a>
                  <a className="btn btn-square btn-outline-light btn-social" ><i className="fab fa-facebook-f"></i></a>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <h5 className="text-white mb-4">Quick Links</h5>
                <a className="btn btn-link" href='#about'>About Us</a>
                <a className="btn btn-link" href='#contact'>Contact Us</a>
                <a className="btn btn-link" href='#service'>Our Services</a>
                <a className="btn btn-link" href='#project'>Our Projects</a>
                <a className="btn btn-link" href='#quote'>Get Quote</a>
              </div>
              <div className="col-lg-3 col-md-6">
                <h5 className="text-white mb-4">Project Gallery</h5>
                <div className="row g-2">
                  <div className="col-4">
                    <img className="img-fluid rounded" src="img/gallery-1.jpg" alt="gallery1" />
                  </div>
                  <div className="col-4">
                    <img className="img-fluid rounded" src="img/gallery-2.jpg" alt="gallery2" />
                  </div>
                  <div className="col-4">
                    <img className="img-fluid rounded" src="img/gallery-3.jpg" alt="gallery3" />
                  </div>
                  <div className="col-4">
                    <img className="img-fluid rounded" src="img/gallery-4.jpg" alt="gallery4" />
                  </div>
                  <div className="col-4">
                    <img className="img-fluid rounded" src="img/gallery-5.jpg" alt="gallery5" />
                  </div>
                  <div className="col-4">
                    <img className="img-fluid rounded" src="img/gallery-6.jpg" alt="gallery6" />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <h5 className="text-white mb-4">Newsletter</h5>
                <p>Subscribe to receive the latest news.</p>
                <div className="position-relative mx-auto" style={{maxWidth: 400}}>
                  <input className="form-control border-0 w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email" />
                  <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="copyright">
              <div className="row">
                <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                  ©2024 <a href="http://tlfsolarenergysystemsrental.com/">TLF Solar Energy Systems Rental</a>, All Right Reserved.
                </div>
    
              </div>
            </div>
          </div>
        </div>
    );
}

export default Footer;