import React from 'react';

function Testimonial(props) {
    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 600}}>
                <h6 className="text-primary">Testimonials</h6>
                <h1 className="mb-4">What Our Clients Say!</h1>
                </div>
                <div className="owl-carousel testimonial-carousel wow fadeInUp" data-wow-delay="0.1s">
                <div className="testimonial-item text-center">
                    <div className="testimonial-img position-relative">
                    <img className="img-fluid rounded-circle mx-auto mb-5" src="img/testimonial-1.jpg" alt="John Doe" />
                    <div className="btn-square bg-primary rounded-circle">
                        <i className="fa fa-quote-left text-white" />
                    </div>
                    </div>
                    <div className="testimonial-text text-center rounded p-4">
                    <p>Our experience with this company has been outstanding. Their professionalism and dedication to our project were evident throughout. They delivered beyond our expectations, and their innovative solutions have greatly enhanced our operations.</p>
                    <h5 className="mb-1">David Scottfield</h5>
                    <span className="fst-italic">CEO, Tech Innovations Inc.</span>
                    </div>
                </div>
                <div className="testimonial-item text-center">
                    <div className="testimonial-img position-relative">
                    <img className="img-fluid rounded-circle mx-auto mb-5" src="img/testimonial-2.jpg" alt="Sarah Smith" />
                    <div className="btn-square bg-primary rounded-circle">
                        <i className="fa fa-quote-left text-white" />
                    </div>
                    </div>
                    <div className="testimonial-text text-center rounded p-4">
                    <p>The team was exceptional in understanding our needs and providing tailored solutions. Their expertise in renewable energy has been invaluable, and we couldn't be happier with the results.</p>
                    <h5 className="mb-1">Hassan Badir</h5>
                    <span className="fst-italic">Managing Director, Green Solutions Ltd.</span>
                    </div>
                </div>
                <div className="testimonial-item text-center">
                    <div className="testimonial-img position-relative">
                    <img className="img-fluid rounded-circle mx-auto mb-5" src="img/testimonial-3.jpg" alt="Michael Brown" />
                    <div className="btn-square bg-primary rounded-circle">
                        <i className="fa fa-quote-left text-white" />
                    </div>
                    </div>
                    <div className="testimonial-text text-center rounded p-4">
                    <p>Working with this company has been a pleasure. Their commitment to excellence and customer satisfaction is evident in every aspect of their work. We have seen significant improvements in our energy efficiency thanks to their solutions.</p>
                    <h5 className="mb-1">John Wu</h5>
                    <span className="fst-italic">Executive Director, Sustainable Energy Corp.</span>
                    </div>
                </div>
                </div>
            </div>
        </div>

    );
}

export default Testimonial;